import React, { useState, useEffect } from "react";
import { auth, signInWithCustomToken } from "../Firebase.js";
import { importAll } from "./Helper";
import Spinner from "./Spinner.js";
import { getStoredOwner } from "./LocalStorage.js";
import "../css/VerifyLoginOtp.css";
import { generateOtp, verifyOtp, reGenerateOtp, getFirebaseToken } from "../Connection.js";


function VerifyLoginOtp(props) {

  const storedOwner = getStoredOwner();
  const otpEntered = { otp: "" };
  const [formOtp, setFormOtp] = useState(otpEntered);
  const [countdown, setCountdown] = useState(60);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otpError, setOtpError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [isDisabled ,  setIsDisabled] = useState(true);

  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );

  useEffect(() => {
    
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    
    // window.recaptchaVerifier = new RecaptchaVerifier(
    //   auth,
    //   "generatingOtp",
    //   {
    //     size: "invisible",
    //     callback: (response) => {
    //     },
    //     "expired-callback": () => {},
    //   },
    //   auth
    // );
    if (orderId === null) {
      // console.log("generateOtp")
      generateOtp('91' + props.mobile, (result) => {
        // console.log("result ", result);
        if (result.status === 'success') {
          setOrderId(result.orderId);
        }
      })
    }

    // signIn();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormOtp({ ...formOtp, [name]: value });
    if(value.length === 6){
      setIsDisabled(false);
    }
    else{
      setIsDisabled(true);
    }
    setOtpError("");
  };


  const verifyEnteredOtp = (event) => {
    setShowSpinner(true)
    verifyOtp('91' + props.mobile, orderId, formOtp.otp, (result) => {
      // console.log("verifyOtp ", result);
      if (result.status === 'success' && result.isOTPVerified === true) {
        //get token
        getFirebaseToken(props.mobile, (token) => {
          // console.log("token ", token)
          signIn(token);
        })
      } else {
        setIsOtpValid(false);
        setOtpError("*Please enter valid OTP");
        setShowSpinner(false);
        // console.log(error)
      }
    })
    // window.confirmationResult
    //   .confirm(formOtp.otp)
    //   .then((result) => {
    //     const user = result.user;
    //     setIsOtpValid(true);
    //     props.getAgent(user);
    //     setOtpError("");
    //     setIsOtpValid(false);
    //     setShowSpinner(false);
    //   })

    //   .catch((error) => {
    //     setIsOtpValid(false);
    //     setOtpError("*Please enter valid OTP");
    //     setShowSpinner(false);
    //     console.log(error)
    //   });
  };

  const resendOtp = (e) => {
    e.preventDefault();
    reGenerateOtp(orderId, (result) => {

    })
  };

  return (
    <>
      {showSpinner && <Spinner />}
      <div className="otp-page">
        <div className="mobile-otp ">
          <div className="ownerDetails">
            <p>Business Owner</p>
            <p>{storedOwner.name}</p>
            <p>{storedOwner.mobile}</p>
          </div>
        </div>
        <div className="owner-box desktop-otp">
          <div className="ownerDetails">
            <p>Business Owner</p>
            <p>{storedOwner.name}</p>
            <p>{storedOwner.mobile}</p>
          </div>
        </div>

        <div className="main-container-otp">
          <div className="cover-image-otp">
            <img src={images["otp1.png"]} alt="" />
          </div>
          <div className="otp-card">
            <div className="otp-card-one">
              <div className="login-welcomeMessage">
                <div className="welcome-message">
                  <p>
                    Welcome <span>Team Member</span>
                  </p>
                  <p>Let's start!!</p>
                </div>

                <div className="otpHeader">
                  <p>OTP Authentication</p>
                </div>
                <div className="otpAuth">OTP Sent On : {props.mobile}</div>
              </div>
            </div>
            <div id="otp" className="inputs">
              <input
                className="otp-input"
                type="tel"
                name="otp"
                placeholder="Enter Otp"
                onChange={handleChange}
                id="first"
                maxLength="6"
              />
            </div>
            {!isOtpValid && <div className="error-message2">{otpError}</div>}
            <div className="Button1">
              <button
                type="button"
                className="validate"
                id="verifyEnteredOtp"
                onClick={verifyEnteredOtp}
                disabled={isDisabled}
              >
                Verify
              </button>
            </div>
            <div className="resend-box">
              <div className="content ">
                {countdown > 0 ? (
                  <h6 className="resend">
                    <span>Resend Otp in </span>
                    <span> </span>
                    {countdown} seconds
                  </h6>
                ) : (
                  <p className="resend" id="resendotpId" onClick={resendOtp}>
                    Resend
                  </p>
                )}
              </div>
            </div>
            <div className="content ">
              <span> </span>
              <p className="resend" id="generatingOtp" onClick={resendOtp}></p>
            </div>
          </div>
        </div>

        <div className="quickLinks" id="q-otp">
          <div className="tagline">
            <p>
              Our <span>Product Portfolio </span>
            </p>
          </div>
          <div className="boxes">
            <div className="box1">
              <img src={images["demat_new.png"]} alt="" />
            </div>
            <div className="box2">
              <img src={images["savings_acc_new.png"]} alt="" />
            </div>
            <div className="box3">
              <img src={images["cc_new.png"]} alt="" />
            </div>
            <div className="box4">
              <img src={images["loan_new.png"]} alt="" />
            </div>
            <div className="box5">
              <img src={images["bnpl_new.png"]} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function signIn(token) {
    // signInWithPhoneNumber(auth, props.mobile, window.recaptchaVerifier)
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
    //   })
    //   .catch((error) => {
    //     console.log("error sending otp ", error);
    //   });

    let promise = signInWithCustomToken(auth, token);
    promise.then(user => {
      // console.log(user.uid)
      props.getAgent(user.user);
      setOtpError("");
      setIsOtpValid(false);
      setShowSpinner(false);
    }).catch(err => {
      // console.log(err.code);
    })

  }
}

export default VerifyLoginOtp;